import { CompetitionDetail } from '@models/Index'
import { FC, HTMLAttributes, useEffect, useMemo } from 'react'

/**
 * Show an overlay layer when:
 * SOLD OUT - status is PUBLISHED and no tickets left, or;
 * DRAWING - status is next to PUBLISHED
 * DRAWN - status is after DRAWING
 */
export const useSoldDrawnOverlay = (status: CompetitionDetail['status'], tickets_left: CompetitionDetail['tickets_left']) => {
  return useMemo(() => (status > 2 ? 'DRAWN' : status == 2 ? 'DRAWING' : status == 1 && !(tickets_left > 0) ? 'CLOSED' : null), [status, tickets_left])
}
type SoldDrawnOverlayProps = { soldOrDrawn: string } & HTMLAttributes<HTMLDivElement>
const SoldDrawnOverlayTop: FC<SoldDrawnOverlayProps & { topId: string }> = props => {
  const { topId, id } = props
  useEffect(() => {
    if (topId && id) {
      const overlay = window.document.getElementById(id)
      const elem = window.document.getElementById(topId)
      overlay.style.top = `-${elem?.clientHeight || 0}px`
    }
  }, [topId, id])
  return <SoldDrawnOverlay {...props} />
}
export const SoldDrawnOverlay: FC<SoldDrawnOverlayProps> & { Top: typeof SoldDrawnOverlayTop } = ({ soldOrDrawn, className, ...rest }) => {
  return (
    <div {...rest} className={`absolute inset-0 flex items-center justify-center bg-stone-50/50 ${className || ''}`}>
      <span className='origin-center rotate-[30deg] rounded bg-error px-4 py-2 uppercase text-white'>{soldOrDrawn}</span>
    </div>
  )
}
SoldDrawnOverlay.Top = SoldDrawnOverlayTop
export default SoldDrawnOverlay
